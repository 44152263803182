import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'dd-client/site/common/components/Link';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { useIsStickyBar } from 'dd-client/site/common/hooks/useIsStickyBar';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { Component } from './types';
import './FooterBottom.scss';

const FooterBottom: Component = (): ReactElement => {
  const { i18n: { language }, t } = useTranslation();

  const isStickyBar = useIsStickyBar();
  const { isMobile } = useDevice();

  const text = useMemo(
    () => ({
      allRightsReserved: t('All rights reserved'),
      currency: t('All prices are in Swiss Francs (CHF) including VAT and taxes'),
      imprint: t('Imprint'),
      privacy: t('Data privacy'),
      termsAndConditions: t('Terms & Conditions'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'Footer-Bottom',
    {
      'Footer-Bottom--BottomSpace': isStickyBar && isMobile,
    },
  );

  return (
    <section className={rootClassName}>
      <ul className="Footer-BottomLinkList">
        <li>
          <Link
            className="Footer-BottomLink"
            url={
              language === LanguageCode.FR
                ? 'https://www.brack.ch/fr/mentions-legales'
                : 'https://www.brack.ch/impressum'
            }
            target="_blank"
            rel="noreferrer"
          >
            {text.imprint}
          </Link>
        </li>
        <li>
          <Link
            className="Footer-BottomLink"
            url={
              language === LanguageCode.FR
                ? 'https://www.brack.ch/fr/conditions-generales-de-vente'
                : 'https://www.brack.ch/unternehmen/agb'
            }
            target="_blank"
            rel="noreferrer"
          >
            {text.termsAndConditions}
          </Link>
        </li>
        <li>
          <a
            className="Footer-BottomLink"
            href={
              language === LanguageCode.FR
                ? '/fr/site/protection-des-donnees '
                : '/de/site/datenschutz'
            }
            target="_blank"
            rel="noreferrer"
          >
            {text.privacy}
          </a>
        </li>
      </ul>
      <div className='Footer-Currency'>
        {text.currency}
      </div>
      <div>
        &copy;&nbsp;
        <a
          className="Footer-BottomLink"
          href={
            language === LanguageCode.FR
              ? 'https://www.brack.ch/fr'
              : 'https://www.brack.ch/'
          }
          suppressHydrationWarning
          target="_blank"
          rel="noreferrer"
        >
          BRACK.CH
        </a> — {text.allRightsReserved}
      </div>
    </section>
  );
};

export {
  FooterBottom,
};
