import React, { ReactElement, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { usePageViewAnalytics } from 'dd-client/site/analytics/hooks/usePageViewAnalytics';
import { Footer } from 'dd-client/site/app/components/App/Footer';
import { Header } from 'dd-client/site/app/components/App/Header';
import { useSseUpdates } from 'dd-client/site/common/hooks/useSseUpdates';
import { useSseUpdatesMonitoring } from 'dd-client/site/common/hooks/useSseUpdatesMonitoring';
import { useThemeInjection } from 'dd-client/site/common/hooks/useThemeInjection';
import { getIsCapitalLettersFeatureActive } from 'dd-client/site/common/utils/features';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { useLanguageSync } from 'dd-client/site/i18n/hooks/useLanguageSync';
import { Component, Props } from './types';
import 'dd-client/site/common/styles/index.scss';
import './App.scss';

const App: Component = ({ route }: Props): ReactElement => {
  useSseUpdates();
  useSseUpdatesMonitoring();
  usePageViewAnalytics();
  useThemeInjection();
  useLanguageSync();
  const queryClient = useQueryClient();

  const rootClassName = classNames(
    'App',
    {
      CapitalLetters: getIsCapitalLettersFeatureActive(),
    },
  );

  useEffect(() => {
    //force activeDeals refetch to avoid caching issues
    queryClient.invalidateQueries({ queryKey: [QUERY.activeDeals.queryName] });
  }, [queryClient]);


  return (
    <div className={rootClassName}>
      <Header />

      <main className="App-Main">
        {renderRoutes(route.routes)}
      </main>

      <Footer />
    </div>
  );
};

export {
  App,
};
